<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">User List</h4>
              <p class="card-title-desc">
                현재까지 등록 된 유저리스트를 보여줍니다.
              </p>

              <div class="mb-3">
                <form class="d-flex" @submit.prevent>
                  <b-form-select
                    v-model="selectedOrder"
                    :options="optionsOrder"
                    class="form-select"
                  ></b-form-select>
                  <b-form-select
                    v-model="selected"
                    :options="options"
                    class="form-select"
                  ></b-form-select>
                  <b-form-input
                    v-model="keyword"
                    id="inline-form-input-name"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="검색어를 입력해주세요."
                    @keyup.enter="getUserList"
                    autocomplete="off"
                  ></b-form-input>
                  <button class="btn btn-primary btn-sm" type="button" @click="getUserList">
                    검색
                  </button>
                </form>
              </div>
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>아이디</th>
                      <th>계정유형</th>
                      <th>이름</th>
                      <th>타입</th>
                      <th>멤버십</th>
                      <th>가입일</th>
                      <th>마지막접속</th>
                      <th>기능</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in userList" :key="item.idx">
                      <th scope="row">
                        {{ totalCnt - (page - 1) * perPage - index }}
                      </th>
                      <td>{{ item.id }}</td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <span v-if="item.authType === 'U'">일반</span>
                        <span v-else>관리자</span>
                      </td>
                      <td>
                        <span v-if="item.premiumYn !== '0'" class="badge bg-danger">유료</span>
                        <span v-else>무료</span>
                      </td>
                      <td>{{ item.regdate | moment('YYYY-MM-DD') }}</td>
                      <td>{{ item.lastLogin }}</td>
                      <td>
                        <button class="btn btn-primary btn-sm me-2" @click="userLogin(item.id)">
                          로그인
                        </button>
                        <button class="btn btn-info btn-sm me-2" @click="userLogin(item.id)">
                          학습관리
                        </button>
                        <button class="btn btn-warning btn-sm me-2" @click="outMember(item.id)">
                          탈퇴
                        </button>
                        <button class="btn btn-danger btn-sm" @click="stopMember(item.id)">
                          정지
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-response -->
              <b-pagination
                v-model="page"
                :total-rows="totalCnt"
                :per-page="perPage"
                align="center"
                aria-controls="my-table"
                class="mt-4"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </div>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'User',
    meta: [{name: 'description', content: appConfig.description}]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: 'User Lists',
      items: [
        {
          text: 'User',
          href: '/'
        },
        {
          text: 'List',
          href: '/'
        },
        {
          text: 'User Lists',
          active: true
        }
      ],
      selected: 'id',
      selectedOrder: 'lastJoin',
      options: [
        {value: 'id', text: '아이디'},
        {value: 'name', text: '이름'}
      ],
      optionsOrder: [
        {value: 'lastJoin', text: '최근가입순'},
        {value: 'lastVisit', text: '최근접속순'}
      ],
      totalCnt: 0,
      perPage: 15,
      page: 1,
      userList: [],
      keyword: ''
    };
  },
  async created() {
    this.getUserList();
  },
  watch: {
    page(newValue) {
      this.page = newValue;
      this.getUserList();
    }
  },
  methods: {
    userLogin(id) {
      window.open('https://www.sucoding.kr/member/authLogin?auth=' + id);
    },
    getUserList() {
      this.$store
        .dispatch('user/getUserList', {
          page: this.page,
          perPage: this.perPage,
          token: sessionStorage.getItem('idToken'),
          selected: this.selected,
          keyword: this.keyword,
          order: this.selectedOrder
        })
        .then(response => {
          this.userList = response.data.result.lists;
          this.totalCnt = response.data.result.totalCnt;
        })
        .catch(err => {
          console.error(err.data.message);
        });
    },
    outMember(id) {
      this.$store.dispatch('user/outMember', {
        type: 'out',
        id: id
      });
    },
    stopMember(id) {
      this.$store.dispatch('user/stopMember', {
        type: 'stop',
        id: id
      });
    }
  }
};
</script>
<style scoped>
table th,
table td {
  text-align: center;
}

form input,
form select {
  min-width: 100px;
  margin-right: 10px;
}
form button {
  min-width: 50px;
}
</style>
